import React from 'react'
import Lottie from 'react-lottie';
import success from '../assets/success.json';

const Success = () => {
    return (
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card">
                    <div className="card-body">
                        <Lottie
                            options={{ animationData: success, loop: false }}
                            height={200}
                            width={200}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Success
