import Axios from "axios";
import React from 'react'
Axios.defaults.baseURL = 'http://manage.comfybroker.com';

const path = "/api/verifydocument";


const Form = ({ enc_type, setStatus }) => {
    const [phone, setPhone] = React.useState("");
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = React.useState(false);
    const handleSend = (evt) => {
        evt.preventDefault();
        setError(false);
        if (phone.length < 10) {
            setWarning(true);
            return;
        } else {
            setWarning(false);
        }
        setLoading(true);
        Axios.post(path, {
            enc_type,
            client_phone_number: phone,
        })
            .then((res) => {
                setStatus(true);
                setLoading(false);
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
            });
    };
    return (
        <div className="row h-full justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card">
                    <div className="bg-soft-primary">
                        <div className="row">
                            <div className="col-7">
                                <div className="text-primary p-4">
                                    <h5 className="text-primary">Welcome !</h5>
                                    <p>Provide detail to verify document</p>
                                </div>
                            </div>
                            <div className="col-5 align-self-end">
                                {/* <img
                                    src={require("../assets/visal-sm-logo.JPG")}
                                    alt=""
                                    className="img"
                                    height="44"

                                /> */}
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSend} className="card-body">
                        <div>
                            <a href="index.html">
                                <div className="avatar-md profile-user-wid mb-4">
                                    <span className="avatar-title rounded-circle bg-white">
                                        <img
                                            src={require("../assets/visal-sm-logo.JPG")}
                                            alt=""
                                            className="rounded-circle"
                                            height="44"
                                        />
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="row">
                            <div className="form-group alert alert-warning">
                                <p>To guarantee document authentication, provide your phone number in the international standard form <br />
                                    <ul>
                                        <li>Country code: 233</li>
                                        <li>Phone number: 274488676</li>
                                    </ul>
                    Example: 233274488676
                  </p>
                            </div>
                        </div>
                        {error && (
                            <div className="alert alert-danger">
                                Contact the system administrator at KEK RE
                            </div>
                        )}
                        {warning && (
                            <div className="alert alert-warning">
                                phone number cannot be less than 10
                            </div>
                        )}
                        <div className="form-group">
                            <input
                                value={enc_type}
                                type="hidden"
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Phone number</label>
                            <input
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                type="tel"
                                className="form-control"
                                placeholder="Eg. 233274488676"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-block btn-primary">
                                {!loading ? (
                                    "Verify"
                                ) : (
                                        <>
                                            {" "}
                                            <span className="bx bx-hourglass bx-spin mr-2"></span>{" "}
                      verifying...{" "}
                                        </>
                                    )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Form