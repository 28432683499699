import React from 'react';
import Form from './components/Form';
import Success from './components/Success';


export default function App() {
  const [success, setSuccess] = React.useState(false);
  const enc_type = window.location.pathname;

  return (
    <div className="container mt-4 py-4">
      {!success && (
        <Form
          enc_type={enc_type.substr(1, enc_type.length)}
          setStatus={(s) => setSuccess(s)}
        />
      )}
      {success && <Success />}
    </div>
  );
}
