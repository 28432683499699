import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './index.css'

const System = () => (
  <BrowserRouter>
    <Route exact path="/:cypher" component={App} />
    <Route exact path="/" component={App} />
  </BrowserRouter>
);

ReactDOM.render(<System />, document.getElementById("root"));

serviceWorker.unregister();
